import styled from "styled-components"
import media from "styled-media-query"
import { colors } from "src/commons"

export const SidebarWrapper = styled.aside`
  display: none;
  background: ${colors.white};
  flex-direction: column;
  height: 100vh;
  position: fixed;
  text-align: center;
  transition: 0.5s ease;
  z-index: 1000;
  margin-top: 62px;

  ${media.lessThan("670px")`
    display: flex;
    align-items: flex-start;
    height: calc(100% - 64px);
    width: 100%;
    transform: ${props =>
      !props.isMenuOpen ? 'translateX(100vw)' : 'translateX(0)'};
  `}
`

export const SidebarLinksContainer = styled.section`
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;

  a {
    font-size: 18px;
    font-weight: 400;
    color: ${colors.black};
    margin-bottom: 20px;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.5s;

    &:hover {
      color: ${colors.purple}
    }
  }
`

export const ButtonWrapper = styled.div`
  margin: 30px;
`