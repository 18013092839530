import { AiOutlineInstagram } from "react-icons/ai"
import { AiOutlineLinkedin } from "react-icons/ai"
import { AiOutlineGithub } from "react-icons/ai"
import { AiOutlineYoutube } from "react-icons/ai"

const handle = "vilaapps"

const links = [
  {
    label: "Instagram",
    url: `https://www.instagram.com/${handle}`,
    Icon: AiOutlineInstagram
  },
  {
    label: "Linkedin",
    url: `https://www.linkedin.com/company/${handle}`,
    Icon: AiOutlineLinkedin
  },
  {
    label: "Github",
    url: `https://github.com/${handle}`,
    Icon: AiOutlineGithub
  },
  {
    label: "Youtube",
    url: `https://www.youtube.com/channel/UCMMKHd78Tvgxi8KaFcKoGbg`,
    Icon: AiOutlineYoutube
  },
]

export default links