import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from './styled'
import links from "./content"

import {AiOutlineCopyrightCircle} from 'react-icons/ai'

const Layout = ({ children }) => {
  const {
    strapiRodapeItem: {endereco, direitos_reservados, logo, siga_nos}
  } = useStaticQuery(
    graphql`
      query {
        strapiRodapeItem {
          siga_nos
          logo {
            childImageSharp {
              fluid(maxWidth: 94) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          endereco
          direitos_reservados
        }
      }
    `
  )

  return (
    <S.FooterWrapper>
      <S.InfosWrapper>
        <S.Address>
          {endereco}
        </S.Address>
        <S.RightsReserved>
          <AiOutlineCopyrightCircle />
          {` `}
          {direitos_reservados}
        </S.RightsReserved>
      </S.InfosWrapper>

      <S.Logo fluid={logo.childImageSharp.fluid}/>

      <S.SocialLinksWrapper>
        <S.FollowUs>
          {siga_nos}
        </S.FollowUs>
        <S.SocialLinksList>
          {links.map(({label, url, Icon}, i) => 
            <S.SocialLinksLink
              key={i}
              title={label}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon />
            </S.SocialLinksLink>
          )}
        </S.SocialLinksList>
      </S.SocialLinksWrapper>
    </S.FooterWrapper>
  )
}

export default Layout
