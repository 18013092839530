export const colors = {
    green: '#34BF98',
    purple: '#4E2559',
    orange: '#F5AE23',
    white: '#FFF',
    black: '#141726',
    lightGray: '#F9F9F9',
    gray: '#BEBEBE'
}

export const globalMaxWidth = '1900px'

export const patterns = {
    phone: ['(99) 9999-9999', '(99) 99999-9999']
}