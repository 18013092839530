import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";

const LinkComponent = ({ link, texto, link_externo, ...props }) => {
  const LinkExternal = ({to, children}) => (
    <a 
      href={to} 
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )

  const Link = link_externo 
    ? LinkExternal
    : AniLink

  return (
    <Link
      to={link}
      {...props}
    >
      {texto}
    </Link>
  )
}

export default LinkComponent
