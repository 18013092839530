import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import * as S from "./styled"

import Link from 'src/components/Link'
import {colors} from 'src/commons'

const HeaderLinks = () => {
  const { 
    allStrapiCabecalhoLink: { nodes: links }
} = useStaticQuery(
      graphql`
        query {
          allStrapiCabecalhoLink(sort: {fields: published_at, order: DESC}) {
            nodes {
              texto
              link
              link_externo
            }
          }
        }
    `
  )

  return (
    <S.LinksWrapper>
      {links.map((link, i) => 
        <Link
          key={i}
          cover
          direction="left"
          bg={colors.lightGray}
          duration={0.6}
          {...link}
        />
      )}
    </S.LinksWrapper>
  )
}

export default HeaderLinks
