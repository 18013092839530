import styled from 'styled-components'
import media from 'styled-media-query'

export const Main = styled.main`
  max-width: 100vw;
  overflow-x: hidden;
  margin-top: 64px;

  ${media.lessThan("large")`
    margin-top: 54px;
  `}
`