import styled from 'styled-components'

import { colors } from 'src/commons'

export const Button = styled.button`
  background: ${props => props.type === "purple" ? colors.purple : colors.white};
  border-radius: 3px;
  cursor: pointer;
  padding: 7px 10px;
  transition: 0.2s;

  color: ${props => props.type === "purple" ? colors.white : colors.black};
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }

  :focus {
    outline: none;
  }

  :active {
    background: ${props => props.type === "purple" ? colors.black : colors.purple};
  }
`