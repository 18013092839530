import React from "react"
import { graphql, useStaticQuery } from 'gatsby'

import Link from 'src/components/Link'
import Button from "src/components/Button"

import * as S from "./styled"
import {colors} from 'src/commons'

const Sidebar = ({
  isMenuOpen,
  setIsMenuOpen
}) => {
  const { 
    strapiCabecalho: {texto_do_botao},
    allStrapiCabecalhoLink: { nodes: links }
  } = useStaticQuery(
      graphql`
        query {
          strapiCabecalho {
            texto_do_botao
          }
          allStrapiCabecalhoLink(sort: {fields: published_at, order: DESC}) {
            nodes {
              texto
              link
              link_externo
            }
          }
        }
    `
  )

  const handleOnClick = () => {
    setIsMenuOpen(false)
    window.open('/#section-7-contact-us', '_self')
  }

  return (
  <S.SidebarWrapper isMenuOpen={isMenuOpen}>
    <S.SidebarLinksContainer>
      {links.map((link, i) => 
        <Link
          cover
          direction="right"
          bg={colors.ligthGray}
          duration={0.6}
          key={i}
          {...link}
        />
      )}
      
      <S.ButtonWrapper>
        <Button 
          text={texto_do_botao}
          onClick={handleOnClick}
          clickGtag={{
            tag:'scroll_to_contact_us', 
            params: {button: 'sidebar'}
          }}
        />
      </S.ButtonWrapper>
    </S.SidebarLinksContainer>
  </S.SidebarWrapper>
)}

export default Sidebar