import styled from 'styled-components'
import media from 'styled-media-query'

import Image from 'gatsby-image'
import { colors, globalMaxWidth } from 'src/commons'

export const FooterWrapper = styled.footer`
  display: flex;
  height: 100px;
  background-color: ${colors.purple};
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;

  ${media.lessThan("860px")`
    height: auto;
    flex-direction: column;
    padding: 30px;
  `}

  ${media.greaterThan(globalMaxWidth)`
    padding: 0 calc(((100vw - ${globalMaxWidth}) / 2) + 30px);
  `}
`

export const InfosWrapper = styled.div`
  width: 300px;

  ${media.lessThan("860px")`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `}
`

export const Address = styled.p`
  font-size: 11px;
  color: ${colors.white};
  font-weight: 100;

  ${media.lessThan("860px")`
    max-width: 300px;
    text-align: center;
    margin-bottom: 5px;
  `}
`

export const RightsReserved = styled.p`
  font-size: 10px;
  color: ${colors.white};
  font-weight: 100;
`

export const Logo = styled(Image)`
  height: 49px;
  width: 94.63px;

  ${media.lessThan("860px")`
    margin: 30px;
  `}
`

export const SocialLinksWrapper = styled.div`
  width: 300px;

  ${media.lessThan("860px")`
    width: 100%;
    max-width: 250px;
  `}
`

export const FollowUs = styled.p`
  width: 100%;
  font-size: 11px;
  color: ${colors.white};
  font-weight: 100;
  text-align: center;
`

export const SocialLinksList = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
`

export const SocialLinksLink = styled.a`
  color: ${colors.white};
  font-size: 18px;

  :hover {
    opacity: 0.8;
  }
`
