import React from "react"
import { gtag } from "src/services/analytics"

import * as S from "./styled"

const Button = ({ text, type = "purple", href, onClick, clickGtag }) => {

  const handleOnClick = () => {
    clickGtag && gtag(clickGtag.tag, clickGtag.params)

    href ? window.open(href, '_self') : onClick()
  }
  return (
    <S.Button
      type={type}
      onClick={handleOnClick}
    >
      {text}
    </S.Button>
  )
}

export default Button
