import React, {useState} from "react"
import PropTypes from "prop-types"

import Header from "src/components/Header"
import Sidebar from "src/components/Sidebar"
import Footer from 'src/components/Footer'

import { TransitionPortal } from "gatsby-plugin-transition-link";

import GlobalStyles from 'src/styles/global'
import * as S from './styled'

import "src/../node_modules/slick-carousel/slick/slick.css"; 
import "src/../node_modules/slick-carousel/slick/slick-theme.css";

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <>
      <GlobalStyles />
      <TransitionPortal level="top">
        <Header 
          setIsMenuOpen={setIsMenuOpen}
          isMenuOpen={isMenuOpen}
        />
      </TransitionPortal>
      <Sidebar 
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
      />
      <S.Main>
        {children}
      </S.Main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
