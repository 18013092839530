import styled from 'styled-components'
import media from 'styled-media-query'

import Image from 'gatsby-image'
import { colors, globalMaxWidth } from 'src/commons'

export const HeaderWrapper = styled.header`
  background: ${colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  z-index: 1000;
  padding: 0 30px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;

  ${media.lessThan("large")`
    padding: 0 15px;
  `}

  ${media.greaterThan(globalMaxWidth)`
    padding: 0 calc(((100vw - ${globalMaxWidth}) / 2) + 30px);
  `}
`

export const Logo = styled(Image)`
  width: 168px;
  height: 64px;
`

export const ButtonWrapper = styled.div`
  ${media.lessThan("890px")`
    display: none;
  `}
`

export const ToggleSidebar = styled.span`
  display: none;

  ${media.lessThan("670px")`
    display: block;
    height: 64px;
    width: 64px;
    margin-right: -20px;
    text-align: center;
    transition: 0.3s;
    transform: ${props => props.isMenuOpen ? `rotate(180deg)` : `rotate(0deg)`};

    svg {
      height: 64px;
      font-size: 28px;
    }

    :hover {
      opacity: 0.8;
    }
  `}
`
