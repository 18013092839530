import PropTypes from "prop-types"
import React from "react"
import { graphql, useStaticQuery } from 'gatsby'

import AniLink from "gatsby-plugin-transition-link/AniLink";
import Button from 'src/components/Button'
import Links from './Links'
import { AiOutlineLeft } from "react-icons/ai";

import * as S from "./styled"
import { colors } from "src/commons"

const Header = ({ isMenuOpen, setIsMenuOpen }) => {
  const { 
    strapiCabecalho: { logo, texto_do_botao }
} = useStaticQuery(
      graphql`
        query {
          strapiCabecalho {
            texto_do_botao
            logo {
              childImageSharp {
                fluid(maxWidth: 168) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
    `
  )

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <S.HeaderWrapper>
      <AniLink
        cover
        direction="right"
        bg={colors.lightGray}
        duration={0.6}
        to="/"
      >
        <S.Logo fluid={logo.childImageSharp.fluid} />
      </AniLink>

      <Links />

      <S.ButtonWrapper>
        <Button 
          text={texto_do_botao}
          href="/#section-7-contact-us"
        />
      </S.ButtonWrapper>

      <S.ToggleSidebar
        title="Abrir Menu" 
        isMenuOpen={isMenuOpen}
        onClick={toggleMenu}
      >
        <AiOutlineLeft />
      </S.ToggleSidebar>
    </S.HeaderWrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
