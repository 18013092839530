import styled from 'styled-components'
import media from 'styled-media-query'

import { colors } from 'src/commons'

export const LinksWrapper = styled.div`
  display: flex;
  padding-right: 40px;

  a {
    font-size: 18px;
    font-weight: 400;
    color: ${colors.black};
    margin-left: 40px;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.5s;

    &:hover {
      color: ${colors.purple}
    }
  }

  ${media.lessThan("large")`
    padding-right: 20px;

    a {
      font-size: 16px;
      margin-left: 20px;
    }
  `}

  ${media.lessThan("670px")`
    display: none;
  `}
`